html, body {
  height: 100%;
  padding: 0 !important; /* workaround to prevent affected by Google AdSense */
}

#root {
  height: 100%;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}

@keyframes self-rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.blink {
  animation: blink 1s ease-in-out infinite;
}

.self_rotate {
  animation-name: self-rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
